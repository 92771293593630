import React, { useState } from 'react';
import RenovationType from '../components/RenovationType';
import RenovationCalculator from '../components/RenovationCalculator';

const Calculator = ({ cartItems }) => {
    const [selectedTypes, setSelectedTypes] = useState(null);

    const handleSelection = (cartItems, selectedTypes) => {
        // Store the selected types in state and pass them to the calculator
        setSelectedTypes({ cartItems, selectedTypes });
    };

    return (
        <div>
            {!selectedTypes ? (
                <RenovationType onSelection={handleSelection} />
            ) : (
                <RenovationCalculator cartItems={selectedTypes.cartItems} selectedTypes={selectedTypes.selectedTypes} />
            )}
        </div>
    );
};

export default Calculator;
