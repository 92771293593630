import React, { useState, useEffect } from 'react';
import MeasurementInputs from './MeasurementInputs';
import CutoutInputs from './CutoutInputs';
import TotalCost from './TotalCost';
import { useNavigate, useLocation } from 'react-router-dom';
import './RenovationCalculator.css';

const RenovationCalculator = ({ selectedTypes, cartItems }) => { 
  const slabsInCart = cartItems || [];

  const [slabAreas, setSlabAreas] = useState(
    slabsInCart.map(slab => ({
      slab,
      type: selectedTypes[slab.productID] || "Unknown",
      areas: [{ id: 1, width: 0, height: 0 }],
      cutouts: 0,
      outlets: 0,
    }))
  );

  useEffect(() => {
    console.log("Selected Types:", selectedTypes);
    console.log("Slab Areas:", slabAreas);
  }, [selectedTypes, slabAreas]);

  const [estimates, setEstimates] = useState([]);
  const navigate = useNavigate();

  const addArea = (slabIndex) => {
    setSlabAreas(slabAreas.map((slabArea, index) =>
      index === slabIndex
        ? {
          ...slabArea,
          areas: [...slabArea.areas, { id: slabArea.areas.length + 1, width: 0, height: 0 }]
        }
        : slabArea
    ));
  };

  const removeArea = (slabIndex, areaId) => {
    setSlabAreas(slabAreas.map((slabArea, index) =>
      index === slabIndex
        ? {
          ...slabArea,
          areas: slabArea.areas.filter(area => area.id !== areaId)
        }
        : slabArea
    ));
  };

  const updateArea = (slabIndex, areaId, field, value) => {
    setSlabAreas(slabAreas.map((slabArea, index) =>
      index === slabIndex
        ? {
          ...slabArea,
          areas: slabArea.areas.map(area =>
            area.id === areaId ? { ...area, [field]: value } : area
          )
        }
        : slabArea
    ));
  };

  const updateCutouts = (slabIndex, value) => {
    setSlabAreas(slabAreas.map((slabArea, index) =>
      index === slabIndex
        ? { ...slabArea, cutouts: value }
        : slabArea
    ));
  };

  const updateOutlets = (slabIndex, value) => {
    setSlabAreas(slabAreas.map((slabArea, index) =>
      index === slabIndex
        ? { ...slabArea, outlets: value }
        : slabArea
    ));
  };

  const handleEstimateCalculated = (index, estimate) => {
    setEstimates(prevEstimates => {
      const updatedEstimates = [...prevEstimates];
      updatedEstimates[index] = {
        name: slabAreas[index].slab.name,
        type: slabAreas[index].type,
        estimate: {
          ...estimate,
          areas: slabAreas[index].areas // Include all areas in the estimate
        }
      };
      return updatedEstimates;
    });
  };

  const handleNext = () => {
    navigate('/calculator/user-info', { state: { estimates } });
  };

  return (
    <div className="renovation-calculator">
      <h1>Renovation Estimate Calculator</h1>
      {slabAreas.map((slabArea, slabIndex) => (
        <div key={slabArea.slab.productID} className="slab-section">
          <h2>{slabArea.slab.name} Stone for the {slabArea.type} Cost Breakdown:</h2>
          {slabArea.areas.map(area => (
            <div key={area.id} className="area-section">
              <h3>Area {area.id} Dimensions</h3>
              <MeasurementInputs
                setWidth={value => updateArea(slabIndex, area.id, 'width', Number(value))}
                setHeight={value => updateArea(slabIndex, area.id, 'height', Number(value))}
              />
              {area.id !== 1 && (
                <button onClick={() => removeArea(slabIndex, area.id)} className="remove-area-button">
                  Remove Area
                </button>
              )}
            </div>
          ))}
          <CutoutInputs 
            setCutouts={value => updateCutouts(slabIndex, value)} 
            setOutlets={value => updateOutlets(slabIndex, value)} 
          />
          <button onClick={() => addArea(slabIndex)} className="add-area-button">
            Add Another Area
          </button>
          <TotalCost
            areas={slabArea.areas}
            stonePrice={parseFloat(slabArea.slab.price.replace(/[^\d.-]/g, '')) + 10} // Adding $10 to the price
            outletCount={slabArea.outlets}
            cutoutCount={slabArea.cutouts}
            fabricationCostPerSqFt={35}
            installationCostPerSqFt={35}
            onEstimateCalculated={(estimate) => handleEstimateCalculated(slabIndex, estimate)}
          />
        </div>
      ))}
      <button onClick={handleNext} className="btn">
        Next
      </button>
    </div>
  );
};

export default RenovationCalculator;
