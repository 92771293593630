import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/logo.png';

const NavbarContainer = styled.nav`
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.div`
  img {
    width: 150px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Optional: Change cursor to pointer */
  }
`;

const NavLinks = styled.div`
  a {
    color: white;
    text-decoration: none;
    margin-right: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    width: 100%;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-right: 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    padding: 10px;
    border-bottom: 1px solid white;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    font-size: 2rem; /* Increase the size of the icon */
    padding: 0.5rem; /* Add some padding for touch-friendly interaction */
  }
`;

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <NavbarContainer>
      <Logo><img src={CompanyLogo} alt="Company Logo" /></Logo>
      <MenuIcon onClick={() => setIsOpen(!isOpen)}>
        <div id='icon'>☰</div>
      </MenuIcon>
      <NavLinks isOpen={isOpen}>
        <NavLink to="/" onClick={handleLinkClick}>Home</NavLink>
        <NavLink to="/about" onClick={handleLinkClick}>About Us</NavLink>
        <NavLink to="/services" onClick={handleLinkClick}>Services</NavLink>
        <NavLink to="/inventory" onClick={handleLinkClick}>Inventory</NavLink>
        <NavLink to="/projects" onClick={handleLinkClick}>Projects</NavLink>
        <NavLink to="/contact" onClick={handleLinkClick}>Contact</NavLink>
      </NavLinks>
    </NavbarContainer>
  );
}

export default Navbar;
