import React, { useEffect } from "react";

const TotalCost = ({
  areas = [],
  stonePrice,
  outletCount,
  cutoutCount,
  fabricationCostPerSqFt,
  installationCostPerSqFt,
  onEstimateCalculated
}) => {
  const totalArea = areas.reduce((acc, area) => {
    const areaWidth = parseFloat(area.width) || 0;
    const areaHeight = parseFloat(area.height) || 0;
    return acc + (areaWidth * areaHeight);
  }, 0);

  const stoneCost = stonePrice * totalArea;
  const outletCost = outletCount * 25;
  const cutoutCost = cutoutCount * 200;
  const fabricationCost = fabricationCostPerSqFt * totalArea;
  const installationCost = installationCostPerSqFt * totalArea;
  const totalCost = stoneCost + outletCost + cutoutCost + fabricationCost + installationCost;

  // Calculate the estimate and send it back to the parent component
  useEffect(() => {
    const estimate = {
      totalArea,
      stoneCost,
      outletCost,
      cutoutCost,
      fabricationCost,
      installationCost,
      totalCost
    };
    onEstimateCalculated(estimate);
  }, [totalArea, stoneCost, outletCost, cutoutCost, fabricationCost, installationCost, totalCost]);

  return (
    <div className="total-cost-container">
      <h2>Estimated Cost Breakdown:</h2>
      <p>Stone Cost: ${stoneCost.toFixed(2)}</p>
      <p>Outlet Cost: ${outletCost.toFixed(2)}</p>
      <p>Cutout Cost: ${cutoutCost.toFixed(2)}</p>
      <p>Fabrication Cost: ${fabricationCost.toFixed(2)}</p>
      <p>Installation Cost: ${installationCost.toFixed(2)}</p>
      <h3>Total Estimated Cost: ${totalCost.toFixed(2)}</h3>
    </div>
  );
};

export default TotalCost;
